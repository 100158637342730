<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="基本情報" icon="">
				<b-field label="名前"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'name') }"
					:message="errors.name">
					<b-input type="text" v-model="name" />
				</b-field>

				<b-field label="コード"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'code') }"
					:message="errors.code">
					<b-input type="text" v-model="code" placeholder="半角英数小文字20文字以内"/>
				</b-field>

				<b-field label="グループ" custom-class="require" horizontal>
					<b-select v-model="groupId">
						<option v-for="(row, index) in groups" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>

				<b-field label="責任者名"
					v-if="!id"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ownerName') }"
					:message="errors.ownerName">
					<b-input type="text" v-model="ownerName"/>
				</b-field>

				<b-field label="責任者名(フリガナ)"
					v-if="!id"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ownerNameKana') }"
					:message="errors.ownerNameKana">
					<b-input type="text" v-model="ownerNameKana"/>
				</b-field>

				<b-field label="責任者Email"
					v-if="!id"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ownerEmail') }"
					:message="$utils.hasError(errors, 'ownerEmail') ? errors.ownerEmail: '※加盟店システムのログインに使用します。'">
					<b-input type="text" v-model="ownerEmail"/>
				</b-field>

				<b-field label="店舗Email"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'email') }"
					:message="$utils.hasError(errors, 'email') ? errors.email: '※納品書などの送信先です。責任者Emailと同じでも可。'">
					<b-input type="text" v-model="email"/>
				</b-field>

				<b-field label="電話番号"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'tel') }"
					:message="errors.tel">
					<b-input type="text" v-model="tel" placeholder="例）090-1234-5678"/>
				</b-field>

				<b-field label="郵便番号"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'postal') }"
					:message="errors.postal">
					<b-input type="text" v-model="postal" placeholder="例）108-0072" @input="onPostalInput"/>
				</b-field>

				<b-field label="都道府県" custom-class="require" horizontal>
					<b-select v-model="prefectureId">
						<option v-for="(row, index) in prefectures" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>

				<b-field label="市区町村"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'city') }"
					:message="errors.city">
					<b-input type="text" v-model="city" placeholder="例）港区"/>
				</b-field>

				<b-field label="町域"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'town') }"
					:message="errors.town">
					<b-input type="text" v-model="town" placeholder="例）白金"/>
				</b-field>

				<b-field label="番地"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'address') }"
					:message="errors.address">
					<b-input type="text" v-model="address" placeholder="例）2-6-14"/>
				</b-field>

				<b-field label="建物名" horizontal>
					<b-input type="text" v-model="building" placeholder="例）白金ビル3F"/>
				</b-field>

				<b-field label="ステータス"
					horizontal
					custom-class="require"
					>
					<radio-picker :options="storeStatuses" v-model="storeStatusId"></radio-picker>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.go(-1)">戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">{{ !id ? '登録' : '更新' }}</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>

			<card-component v-if="id" title="責任者情報" icon="">
				<b-field label="名前"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ownerName') }"
					:message="errors.ownerName">
					<b-input type="text" v-model="ownerName"/>
				</b-field>

				<b-field label="フリガナ"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ownerNameKana') }"
					:message="errors.ownerNameKana">
					<b-input type="text" v-model="ownerNameKana"/>
				</b-field>

				<b-field label="Email"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'ownerEmail') }"
					:message="errors.ownerEmail">
					<b-input type="text" v-model="ownerEmail"/>
				</b-field>

				<b-field class="form-buttons" horizontal>
					<b-field grouped>
						<div class="control">
							<b-button type="is-primary" class="" :loading="isOwnerLoading" @click="onOwnerUpdateClick">更新する</b-button>
						</div>
						<div class="control">
							<b-button type="is-warning" class="password-reset" :loading="isOwnerPasswordResetLoading" @click="onOwnerPasswordResetClick">パスワードリセット</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>
		</section>
	</div>
</template>

<style lang="scss" scoped>
.password-reset {
	width: 200px;
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'
import { jsonp } from 'vue-jsonp'

export default {
	name: 'StoreEdit',
	components: {
		RadioPicker,
		CardComponent,
		TitleBar,
	},
	data () {
		return {
			id: '',
			name: '',
			code: '',
			ownerId: '',
			ownerName: '',
			ownerNameKana: '',
			ownerEmail: '',
			tel: '',
			email: '',
			postal: '',
			city: '',
			town: '',
			address: '',
			building: '',
			groupId: null,
			storeStatusId: null,
			prefectureId: null,
			errors: {
				name: '',
				code: '',
				tel: '',
				email: '',
				postal: '',
				city: '',
				town: '',
				address: '',
				ownerName: '',
				ownerNameKana: '',
				ownerEmail: '',
			},
			isLoading: false,
			isOwnerLoading: false,
			isOwnerPasswordResetLoading: false,
			storeStatuses: null,
			groups: null,
			prefectures: null,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		onOwnerUpdateClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isOwnerLoading = true;
			let data = {
				"id": this.ownerId,
				"name": this.ownerName,
				"name_kana": this.ownerNameKana,
				"email": this.ownerEmail,
			};
			this.$utils.log(data);

			let ep = 'users/' + this.ownerId;
			this.$utils.log(ep);
			
			try {
				let res = await this.$api.put(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('店舗責任者情報を更新しました');
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isOwnerLoading = false;
			}
		},
		onOwnerPasswordResetClick: async function() {
			if (!window.confirm('パスワードをリセットします。よろしいですか？')) return false;

			this.isOwnerPasswordResetLoading = true;

			let data = {
				"id": this.ownerId,
			};
			this.$utils.log(data);

			let ep = 'users/reset-password';
			this.$utils.log(ep);
			
			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('パスワードをリセットしてメールを送信しました');
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isOwnerPasswordResetLoading = false;
			}
		},
		onPostalInput: async function() {
			let ep = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + this.postal;

			jsonp(ep).then(json => {
				if (json.status !== 200) return false;
				let data = json.results[0];
				//this.$utils.log(data);
				this.prefectureId = data.prefcode;
				this.city = data.address2;
				this.town = data.address3;
			}).catch(err => {
			});
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.storeId ? '/' + this.$route.params.storeId : '';
			let ep = 'stores/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.name = res.data.name;
				this.code = res.data.code;
				this.ownerId = res.data.owner_id;
				this.ownerName = res.data.owner_name;
				this.ownerNameKana = res.data.owner_name_kana;
				this.ownerEmail = res.data.owner_email;
				this.tel = res.data.tel;
				this.email = res.data.email;
				this.postal = res.data.postal;
				this.city = res.data.city;
				this.town = res.data.town;
				this.address = res.data.address;
				this.building = res.data.building;
				this.groupId = res.data.group_id;
				this.prefectureId = res.data.prefecture_id;
				this.storeStatusId  = res.data.store_status_id;
				this.groups = res.data.groups;
				this.storeStatuses = res.data.store_statuses;
				this.prefectures = res.data.prefectures;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"name": this.name,
				"code": this.code,
				"tel": this.tel,
				"email": this.email,
				"postal": this.postal,
				"city": this.city,
				"town": this.town,
				"address": this.address,
				"building": this.building,
				"prefecture_id": this.prefectureId,
				"group_id": this.groupId,
				"store_status_id": this.storeStatusId,
			};

			if (!this.id) {
				data.owner_name = this.ownerName;
				data.owner_name_kana = this.ownerNameKana;
				data.owner_email = this.ownerEmail;
			}

			this.$utils.log(data);

			try {
				let ep = 'stores';

				if (!this.id) {
					await this.$api.post(ep, data, true);
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
				this.$router.push({ name: 'store' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
	}
}
</script>
